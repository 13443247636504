<template>
  <a-card :bordered="false">
    <div class="card-title">{{ $route.meta.title }}</div>
    <a-spin :spinning="isLoading">
      <a-form :form="form" @submit="handleSubmit">
        <a-divider orientation="left">工资算法</a-divider>
        <a-form-item label="选择算法" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-alert message="算法一经选定后不可再次更改，如需更改请联系客服" banner/>
          <a-radio-group
              :disabled="!!record.algorithm"
              v-decorator="['algorithm', {initialValue:1,rules: [{required: true, message: '请选择算法'}]}]"
          >
            <!--          <a-radio-group-->
            <!--              v-decorator="['algorithm', {initialValue:1,rules: [{required: true, message: '请选择算法'}]}]"-->
            <!--          >-->
            <a-radio :value="1">
              基本工资 + 提成（趟）
            </a-radio>
            <a-radio :value="2">
              基本工资 + 提成（公里数）
            </a-radio>
            <a-radio :value="3">
              基本工资 + 提成（订单营收）
            </a-radio>
          </a-radio-group>
        </a-form-item>

        <template v-if="form.getFieldValue('algorithm')==2">
          <a-form-item label="每公里提成金额（驾驶员）" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
                suffix="元 / 公里"
                placeholder="请输入"
                v-decorator="['driver_kilometer_price', {rules: [{required: true, message: '请输入每公里提成金额'}]}]"
            />
          </a-form-item>
          <a-form-item label="每公里提成金额（押运员）" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
                suffix="元 / 公里"
                placeholder="请输入"
                v-decorator="['supercargo_kilometer_price', {rules: [{required: true, message: '请输入每公里提成金额'}]}]"
            />
          </a-form-item>
        </template>
        <template v-if="form.getFieldValue('algorithm')==3">
          <a-form-item label="订单营收比例（驾驶员）" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
                suffix="%"
                placeholder="请输入"
                v-decorator="['driver_percentage', {rules: [{required: true, message: '请输入订单营收比例'}]}]"
            />
          </a-form-item>
          <a-form-item label="订单营收比例（押运员）" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input
                suffix="%"
                placeholder="请输入"
                v-decorator="['supercargo_percentage', {rules: [{required: true, message: '\'请输入订单营收比例'}]}]"
            />
          </a-form-item>
        </template>

        <a-divider orientation="left">基本工资与固定补贴</a-divider>

        <a-form-item label="驾驶员基本工资" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
              suffix="元 / 天"
              placeholder="请输入基本工资"
              v-decorator="['driver', {rules: [{required: true, message: '请输入基本工资'}]}]"
          />
        </a-form-item>

        <a-form-item label="驾驶员固定补贴" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-button size="small" type="primary" @click="handleAddSubsidy(driverFixedSubsidy)">增加一项</a-button>
          <div class="table">
            <div class="tr">
              <div class="td">补贴名称</div>
              <div class="td">补贴金额</div>
              <div class="td">操作</div>
            </div>
            <div class="tr" v-for="(item,index) in driverFixedSubsidy">
              <div class="td">
                <a-input v-model="item.name"/>
              </div>
              <div class="td">
                <a-input v-model="item.amount"/>
              </div>
              <div class="td">
                <a @click="driverFixedSubsidy.splice(index,1)">删除</a>
              </div>
            </div>
          </div>
        </a-form-item>


        <a-form-item label="押运员基本工资" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
              suffix="元 / 天"
              placeholder="请输入基本工资"
              v-decorator="['supercargo', {rules: [{required: true, message: '请输入基本工资'}]}]"
          />
        </a-form-item>


        <a-form-item label="押运员固定补贴" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-button size="small" type="primary" @click="handleAddSubsidy(supercargoFixedSubsidy)">增加一项</a-button>
          <div class="table">
            <div class="tr">
              <div class="td">补贴名称</div>
              <div class="td">补贴金额</div>
              <div class="td">操作</div>
            </div>
            <div class="tr" v-for="(item,index) in supercargoFixedSubsidy">
              <div class="td">
                <a-input v-model="item.name"/>
              </div>
              <div class="td">
                <a-input v-model="item.amount"/>
              </div>
              <div class="td">
                <a @click="supercargoFixedSubsidy.splice(index,1)">删除</a>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: wrapperCol.span, offset: labelCol.span }">
          <a-button type="primary" html-type="submit">提交</a-button>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-card>
</template>

<script>
import pick from 'lodash.pick'
import * as Api from '@/api/setting/store'
import {isEmpty} from '@/utils/util'

export default {
  data() {
    return {
      // 当前设置项的key
      key: 'salary',
      // 标签布局属性
      labelCol: {span: 3},
      // 输入框布局属性
      wrapperCol: {span: 10},
      // loading状态
      isLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录详情
      record: {},

      driverFixedSubsidy: [
        {name: '', amount: ''},
      ],
      supercargoFixedSubsidy: [
        {name: '', amount: ''},
      ]
    }
  },
  // 初始化数据
  created() {
    // 获取当前详情记录
    this.getDetail()
  },
  methods: {
    handleAddSubsidy(list) {
      list.push({name: '', amount: ''})
    },


    // 获取当前详情记录
    getDetail() {
      this.isLoading = true
      Api.detail(this.key)
          .then(result => {
            // 当前记录
            this.record = result.data.values
            // 设置默认值
            this.setFieldsValue()
          })
          .finally(result => {
            this.isLoading = false
          })
    },

    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {record, $nextTick, form} = this
      !isEmpty(form.getFieldsValue()) && $nextTick(() => {
        form.setFieldsValue(pick(record, ['algorithm', 'driver', 'supercargo']))

        this.driverFixedSubsidy = record.driverFixedSubsidy
        this.supercargoFixedSubsidy = record.supercargoFixedSubsidy

        $nextTick(() => {
          form.setFieldsValue(pick(record, ['driver_kilometer_price', 'supercargo_kilometer_price', 'driver_percentage', 'supercargo_percentage']))
        })
      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      // 表单验证
      const {form: {validateFields}} = this
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.isLoading = true

      values.driverFixedSubsidy = this.driverFixedSubsidy
      values.supercargoFixedSubsidy = this.supercargoFixedSubsidy

      Api.update(this.key, {form: values})
          .then((result) => {
            // 显示提示信息
            this.$message.success(result.message, 1.5)
          })
          .finally((result) => {
            this.isLoading = false
          })
    }

  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-form-item-control {
  padding-left: 10px;

  .ant-form-item-control {
    padding-left: 0;
  }
}

.ant-divider {
  margin-top: 50px !important;
}


.table {
  border: 1px #e8e8e8 solid;

  .tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px #d9d9d9 solid;
    height: 40px;
    line-height: 40px;

    &:last-child {
      border-bottom: none;
    }

    .td {
      padding: 0 10px;
      height: 40px;
      width: 100%;
      text-align: center;
      border-right: 1px #d9d9d9 solid;


      &:last-child {
        border-right: none;
      }
    }
  }
}
</style>
