<template>
  <a-card :bordered="false">
    <div class="card-title">{{ $route.meta.title }}</div>
    <a-spin :spinning="isLoading">
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item label="税金比例" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
              suffix="小数"
              placeholder="请输入税金比例"
              v-decorator="['taxes_proportion', {rules: [{required: true, message: '请输入税金比例'}]}]"
          />
        </a-form-item>
        <a-form-item label="每月固定开支" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-button size="small" type="primary" @click="handleAdd()">增加一项</a-button>
          <div class="table">
            <div class="tr">
              <div class="td">开支名称</div>
              <div class="td">金额</div>
              <div class="td" style="width: 40%">针对车辆</div>
              <div class="td">操作</div>
            </div>
            <div class="tr" v-for="(item,index) in fixed_expenses">
              <div class="td" style="width: 20%">
                <a-input v-model="item.name"/>
              </div>
              <div class="td" style="width: 20%">
                <a-input v-model="item.amount"/>
              </div>
              <div class="td" style="width: 40%">
                <!--                <a-radio-group-->
                <!--                    v-model="item.type"-->
                <!--                >-->
                <!--                  <a-radio :value="0">-->
                <!--                    所有车辆-->
                <!--                  </a-radio>-->
                <!--                  <a-radio :value="1">-->
                <!--                    指定车辆-->
                <!--                  </a-radio>-->
                <!--                </a-radio-group>-->

                <a-select
                    v-if="item.type===1"
                    v-model="item.vehicle_ids"
                    style="width: 240px"
                    allowClear
                    show-search
                    mode="multiple"
                    :filter-option="filterOption"
                    placeholder="请选择车辆"
                >
                  <a-select-option v-for="item in vehicle_list" :value="item.vehicle_id" :item="item">
                    {{ item.vehicle_name }}
                  </a-select-option>
                </a-select>
                <a @click="handleSelectAll(index)" style="margin-left: 5px">全选</a>

              </div>
              <div class="td" style="width: 20%">
                <a @click="handleSubmit" style="margin-right: 10px">保存</a>
                <a @click="handledel(index)">删除</a>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: wrapperCol.span, offset: labelCol.span }">
          <a-button type="primary" html-type="submit">提交</a-button>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-card>
</template>

<script>
import pick from 'lodash.pick'
import * as Api from '@/api/setting/store'
import {isEmpty} from '@/utils/util'
import * as vehicleApi from "@/api/vehicle";

export default {
  data() {
    return {
      // 当前设置项的key
      key: 'vehicle_bill',
      // 标签布局属性
      labelCol: {span: 3},
      // 输入框布局属性
      wrapperCol: {span: 20},
      // loading状态
      isLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录详情
      record: {},

      fixed_expenses: [{name: '', amount: '', type: 1, vehicle_ids: []}],
      vehicle_list: []
    }
  },
  // 初始化数据
  created() {
    // 获取当前详情记录
    this.getDetail()
    // 车辆信息
    vehicleApi.getSelectList().then(({data: {list}}) => {
      this.vehicle_list = list
    })
  },
  methods: {
    // 多选框搜索配置
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleAdd() {
      this.fixed_expenses.push({name: '', amount: '', type: 1, vehicle_ids: []})
    },

    handleSelectAll(index) {
      this.fixed_expenses[index].vehicle_ids = this.vehicle_list.map(item => {
        return item.vehicle_id
      })
    },
    handledel(index) {
      if (this.fixed_expenses.length <= 1) {
        this.$message.error('至少保留一项')
        return false
      }
      this.fixed_expenses.splice(index, 1)
    },
    // 获取当前详情记录
    getDetail() {
      this.isLoading = true
      Api.detail(this.key)
          .then(result => {
            // 当前记录
            this.record = result.data.values
            // 设置默认值
            this.setFieldsValue()
          })
          .finally(result => {
            this.isLoading = false
          })
    },

    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {record, $nextTick, form} = this
      !isEmpty(form.getFieldsValue()) && $nextTick(() => {
        form.setFieldsValue(pick(record, ['taxes_proportion']))

        if (record.fixed_expenses) {
          this.fixed_expenses = record.fixed_expenses
        }

      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      // 表单验证
      const {form: {validateFields}} = this
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.isLoading = true
      values.fixed_expenses = this.fixed_expenses
      Api.update(this.key, {form: values})
          .then((result) => {
            // 显示提示信息
            this.$message.success(result.message, 1.5)
          })
          .finally((result) => {
            this.isLoading = false
          })
    }

  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-form-item-control {
  padding-left: 10px;

  .ant-form-item-control {
    padding-left: 0;
  }
}

.ant-divider {
  margin-top: 50px !important;
}


.table {
  border: 1px #e8e8e8 solid;

  .tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px #d9d9d9 solid;
    line-height: 40px;

    &:last-child {
      border-bottom: none;
    }

    .td {
      padding: 0 10px;
      width: 20%;
      text-align: center;
      border-right: 1px #d9d9d9 solid;


      &:last-child {
        border-right: none;
      }
    }
  }
}
</style>
